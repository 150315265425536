export default defineNuxtRouteMiddleware(async (_to, _from) => {
	const shopStore = useShopStore();
	const { nearbyShops } = storeToRefs(shopStore);

	if (nearbyShops.value.length == 0) {
		await shopStore.getNearbyShops();
	}

	const shop = nearbyShops.value.find((shop) => shop.id == Number.parseInt(_to.params.id as string));

	if (shop) {
		shopStore.setSelectedShop(shop);
	} else {
		return navigateTo('/');
	}
});
